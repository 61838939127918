import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Button } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { useConfigContext } from './WizardProvider';

export function LayoutImage() {
  const { guid } = useParams();
  const { db } = useConfigContext();
  const image = `https://prod-apc-backend-api-bucketbucketf19722a9-xegsuu1nhsof.s3.us-east-1.amazonaws.com/${db}/layout-files/${guid}/layout.jpg`;

  return (
    <>
      <FalconLightBoxGallery images={[image]}>
        {setImgIndex => (
          <Row className="g-2">
            <img
              alt="Digital Layout"
              src={image}
              width="350"
              onClick={() => setImgIndex(0)}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        )}
      </FalconLightBoxGallery>
      <p className="fs--1" style={{ fontStyle: 'italic', textAlign: 'center' }}>
        Tap image to expand
      </p>
    </>
  );
}

function LayoutApproval() {
  const { job, handleDigitalLayout } = useContext(WizardContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState('');

  useEffect(() => {
    // if (!job.roomInfo) {
    //   navigate('../');
    // } else {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
  }, []);

  useEffect(() => {
    // console.log('template status', job?.template?.status);
    // console.log('job', job);
    if (
      job.id &&
      (job?.install?.status === 'Installed' || job?.digitalLayout)
    ) {
      navigate('../');
    }
  }, [job.id, job?.install?.status]);

  return (
    <Wrapper>
      <div className="container">
        <h4>
          Your Digital Layout
          {/* <Link to={'..'}>
            <FontAwesomeIcon icon="home" className="float-end" />
          </Link> */}
        </h4>
        <p>
          The image below is a representative digital layout created by our
          experts for your project:
        </p>
        {/* <Row className="flex-center pt-1 pb-3">
          <img
            alt="Tile Backsplash"
            src="https://precisioncountertops.com/wp-content/uploads/2024/06/tile-install-scaled.jpg"
            width="350"
          />
        </Row> */}

        <LayoutImage />

        {/* <img
          alt="Tile Backsplash"
          src={image}
          // className="min-vw-100 max-vw-100"
          // style={{ width: '98vw', marginLeft: 'calc(50% - 49vw)' }}
          style={{ width: '99vw', marginLeft: 'calc(50% - 49.5vw)' }}
          // style={{ width: '100vw', marginLeft: 'calc(50% - 50vw)' }}
        /> */}

        <Row className="mt-3">
          <p>Select an option below (required):</p>
        </Row>

        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="yes"
              label="Yes - Looks great, please keep my project on track!"
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('yes');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="no"
              label="No - Stop my project and put it on hold, call me to discuss"
              checked={decision === 'no'}
              onChange={() => {
                setDecision('no');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleDigitalLayout(decision);
            navigate('../');
          }}
        >
          Submit My Selection
        </Button>
      </div>
    </Wrapper>
  );
}

export default LayoutApproval;
